<!-- 基本信息 -->
<template>
  <div>
    <div>
      <div class="actions flex jc-end mb-4">
        <a-button v-if="isEditProduction" type="default" class="mr-3" @click="cancel()">取消</a-button>
        <a-button
          v-if="!isAddProduction && formData.isTongChou"
          type="primary"
          @click="editProduction"
          icon="edit"
          v-has="'production:info:button:edit'"
          >{{ !isEditProduction ? '编辑' : '保存' }}</a-button
        >
      </div>
      <div v-if="isEditProduction || isAddProduction">
        <a-form-model ref="form" :model="formData" :rules="rules" v-bind="layout">
          <a-form-model-item label="作品名称" prop="productionName">
            <a-input v-model="formData.productionName" placeholder="请输入作品名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="原文名" prop="foreignName">
            <a-input v-model="formData.foreignName" placeholder="请输入原文名"></a-input>
          </a-form-model-item>
          <a-form-model-item label="暂用名" prop="temporaryName">
            <a-input v-model="formData.temporaryName" placeholder="请输入暂用名"></a-input>
          </a-form-model-item>
          <a-form-model-item label="序号">
            <a-input-number :default-value="1" v-model="formData.sequenceNo" placeholder="序号"></a-input-number>
          </a-form-model-item>
          <a-form-model-item label="平台" prop="platformId">
            <a-spin :spinning="isLoadingPlatformList">
              <a-select
                style="width: 100%"
                placeholder="请选择平台"
                v-model="formData.platformId"
                @change="handelPlatformChange"
              >
                <a-select-option :disabled="!i.active" v-for="i in platformList" :key="i.id">
                  {{ i.department.departName + '-' + i.platformName }}
                </a-select-option>
              </a-select>
            </a-spin>
          </a-form-model-item>
          <a-form-model-item label="编辑" v-if='formData.departName&&formData.departName.indexOf("原创")>-1'>
            <a-select
              style="width: 100%"
              mode="multiple"
              placeholder="请选择编辑人员"
              option-filter-prop="label"
              show-search
              v-model="formData.allTaskAccessUserIds"
            >
              <template v-for="i in allUsers.filter(i=>i.positions.every(p=>p.positionName!='BOSS'&&p.positionName!='HR'))">
                <a-select-option :label="i.userName" :value="i.userId" :key="i.userId">
                  <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="i.avatar" alt="" />
                  <span>{{ i.userName }}</span>
                </a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
          <!--           <a-form-model-item label="统筹" prop="adminUserId">
            <a-select
              style="width: 100%"
              placeholder="请选择统筹人员"
              option-filter-prop="label"
              @change="adminUserChange"
              :default-value="formData.adminUserId"
            >
              <template v-for="i in adminUsers">
                <a-select-option v-if="userShow(i)" :label="i.userName" :value="i.userId" :key="i.userId">
                  <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="i.avatar" alt="" />
                  <span>{{ i.userName }}</span>
                </a-select-option>
              </template>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label="本地化" prop="canTra">
            <a-radio-group
              v-model="formData.isLocal"
              :options="[
                { label: '是', value: true },
                { label: '否', value: false }
              ]"
            />
          </a-form-model-item>
          <a-form-model-item label="使用系统" prop="formType">
            <!--             <a-radio-group v-model="formData.formType">
              <a-radio value="COMIC">
                漫画
              </a-radio>
              <a-radio value="NOVEL">
                文字
              </a-radio></a-radio-group
            > -->
            <a-checkbox-group
              style="display:flex;line-height:40px"
              v-model="formData.systemTypes"
              :options="systemList"
            />
          </a-form-model-item>
          <a-form-model-item v-if="formData.systemTypes" label="是否机翻" prop="isMachineTranslation">
            <a-radio-group
              v-model="formData.isMachineTranslation"
              :options="[
                { label: '是', value: true },
                { label: '否', value: false }
              ]"
            />
          </a-form-model-item>
          <a-form-model-item
            label="翻译语种"
            v-if="
              formData.systemTypes &&
                formData.systemTypes.some(
                  item => item == 'IMAGE_TRANSLATION_SYSTEM' || item == 'TEXT_TRANSLATION_SYSTEM'
                )
            "
          >
            <a-select
              showSearch
              allowClear
              showArrow
              placeholder="请选择源语种"
              v-model="formData.sourceLang"
              style="width:150px;margin-right:5px"
              option-filter-prop="label"
            >
              <a-select-option value="SIMPLIFIED_CHINESE">
                简体中文
              </a-select-option>
              <a-select-option value="TRADITIONAL_CHINESE">
                繁體中文
              </a-select-option>
              <a-select-option value="KOREAN">
                한국어
              </a-select-option>
              <a-select-option value="JAPANESE">
                日本語
              </a-select-option>
              <a-select-option value="ENGLISH">
                ENGLISH
              </a-select-option>
              <a-select-option value="FRENCH">
                Français
              </a-select-option>
              <a-select-option value="GERMAN">
                Deutsch
              </a-select-option>
              <a-select-option value="ITALIAN">
                ITALIAN
              </a-select-option>
            </a-select>
            <a-icon type="arrow-right" />
            <a-select
              showSearch
              allowClear
              showArrow
              placeholder="请选择目标语种"
              style="width:150px;margin-left:5px"
              v-model="formData.targetLang"
              option-filter-prop="label"
            >
              <a-select-option value="SIMPLIFIED_CHINESE">
                简体中文
              </a-select-option>
              <a-select-option value="TRADITIONAL_CHINESE">
                繁體中文
              </a-select-option>
              <a-select-option value="KOREAN">
                한국어
              </a-select-option>
              <a-select-option value="JAPANESE">
                日本語
              </a-select-option>
              <a-select-option value="ENGLISH">
                ENGLISH
              </a-select-option>
              <a-select-option value="FRENCH">
                Français
              </a-select-option>
              <a-select-option value="GERMAN">
                Deutsch
              </a-select-option>
              <a-select-option value="ITALIAN">
                ITALIAN
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否考核" prop="hasKpi">
            <a-radio-group v-model="formData.hasKpi">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio></a-radio-group
            >
          </a-form-model-item>
          <a-form-model-item label="结算单位" prop="calcUnits">
            <a-select
              style="width: 100%"
              mode="multiple"
              :defaultValue="calcUnits"
              option-filter-prop="label"
              @filterOption="filterOption"
              placeholder="请选择"
              v-model="calcUnits"
            >
              <a-select-option
                label="个"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'EACH')"
                value="EACH"
                >个</a-select-option
              >
              <a-select-option
                label="话"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'CHAPTER')"
                value="CHAPTER"
                >话</a-select-option
              >
              <a-select-option
                label="部"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'VOLUME')"
                value="VOLUME"
                >部</a-select-option
              >
              <a-select-option
                label="页"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'PAGE')"
                value="PAGE"
                >页</a-select-option
              >
              <a-select-option
                label="千字"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'THOUSAND')"
                value="THOUSAND"
                >千字</a-select-option
              >
              <a-select-option
                label="字"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'WORD')"
                value="WORD"
                >字</a-select-option
              >
              <a-select-option
                label="格"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'GRID')"
                value="GRID"
                >格</a-select-option
              >
              <a-select-option
                label="%"
                :disabled="calcUnits.length > 1 && calcUnits.every(item => item != 'PERCENT')"
                value="PERCENT"
                >%</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <!--           <a-form-model-item label="章节标题">
            <a-checkbox
              v-model="formData.hasChapterTitle"
              placeholder=""
            ></a-checkbox>
          </a-form-model-item>
          <a-form-model-item label="章节封面">
            <a-checkbox
              v-model="formData.hasChapterCoverImage"
              placeholder=""
            ></a-checkbox>
          </a-form-model-item> -->
        </a-form-model>
      </div>
      <div v-else v-has="'production:info:page:view'">
        <info-item :labelWidth="labelWidth" label="作品名称">{{ formData.productionName }}</info-item>
        <info-item :labelWidth="labelWidth" label="原文名">{{ formData.foreignName }}</info-item>
        <info-item :labelWidth="labelWidth" label="暂用名">{{ formData.temporaryName }}</info-item>
        <info-item :labelWidth="labelWidth" label="序号">{{ formData.sequenceNo }}</info-item>
        <info-item v-if="!isJf" :labelWidth="labelWidth" label="平台">{{ formData.platformName }}</info-item>
        <info-item v-if='formData.departName&&formData.departName.indexOf("原创")>-1' :labelWidth="labelWidth" label="编辑">
          <span v-for="(userId,index) in formData.allTaskAccessUserIds" :key='index'>
            {{getEditUser(userId)}}{{index==formData.allTaskAccessUserIds.length-1?'':'，'}}
          </span>
        </info-item>
        <info-item v-if="!isJf" :label-width="labelWidth" label="本地化">{{
          formData.isLocal ? '是' : '否'
        }}</info-item>
        <!--         <info-item :label-width="labelWidth" v-if="formData.isLocal" label="类型">{{
          formData.formType === 'COMIC' ? '漫画' : formData.formType === 'NOVEL' ? '文字' : ''
        }}</info-item> -->
        <info-item
          :label-width="labelWidth"
          v-if="formData.systemTypes && formData.systemTypes.length && !isJf"
          label="使用系统"
          >{{
            formData.systemTypes
              .map(item => {
                return systemList.find(i => i.value == item) ? systemList.find(i => i.value == item).label : ''
              })
              .join(',')
          }}</info-item
        >
        <info-item v-if="formData.systemTypes && !isJf" :label-width="labelWidth" label="是否机翻">{{
          formData.isMachineTranslation ? '是' : '否'
        }}</info-item>
        <info-item
          :label-width="labelWidth"
          v-if="
            formData.systemTypes &&
              !isJf &&
              formData.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM' || item == 'TEXT_TRANSLATION_SYSTEM')
          "
          label="翻译语种"
        >
          <span style="margin-right:5px">{{
            languageList.find(item => item.value == formData.sourceLang)
              ? languageList.find(item => item.value == formData.sourceLang).label
              : ''
          }}</span>
          <a-icon type="arrow-right" />
          <span style="margin-left:5px">{{
            languageList.find(item => item.value == formData.targetLang)
              ? languageList.find(item => item.value == formData.targetLang).label
              : ''
          }}</span>
        </info-item>
        <info-item v-if="!isJf" :label-width="labelWidth" label="是否考核">{{
          formData.hasKpi ? '是' : '否'
        }}</info-item>
        <info-item v-if="!isJf" :labelWidth="labelWidth" label="结算单位">
          {{ getCalcUnits(formData.calcUnits) }}</info-item
        >
        <!--         <info-item
          :labelWidth="labelWidth"
          label="章节标题"
        >{{ formData.hasChapterTitle | hasOrNot }}</info-item>
        <info-item
          :labelWidth="labelWidth"
          label="章节封面"
        >{{ formData.hasChapterCoverImage | hasOrNot }}</info-item> -->
      </div>
    </div>
  </div>
</template>

<script>
import { postAction, putAction } from '@/api/manage'
import infoItem from '@/components/my/info-item.vue'
import { USER_INFO } from '@/store/mutation-types'
import Vue from 'vue'
import { getAction } from '../../../api/manage'
export default {
  components: {
    infoItem
  },
  props: {
    tab: {
      type: String,
      default: ''
    },
    positionId: {
      type: String,
      default: ''
    },
    isAddProduction: {
      type: Boolean,
      default: false
    },
    isJf: {
      type: Boolean,
      default: false
    },
    productionData: {
      type: Object,
      default: () => ({})
    },
    adminUsers: {
      type: Array,
      default: () => []
    },
    platformList: {
      type: Array,
      default: () => []
    },
    isLoadingUserList: {
      type: Boolean,
      default: () => false
    },
    isLoadingPlatformList: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    let validateProductionName = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入作品名称'))
      } else {
        if (this.oldData.productionName != value) {
          const res = await getAction('/sys/api/checkDataPresent', {
            table: 'mx_production',
            property: 'productionName',
            value
          })
          try {
            if (res.data) {
              callback(new Error('作品名称已存在'))
            } else {
              callback()
            }
          } catch (err) {}
        } else {
          callback()
        }
      }
    }
    return {
      calcUnits: [],
      systemList: [
        { value: 'IMAGE_TRANSLATION_SYSTEM', label: '图片翻译系统' },
        { value: 'TEXT_TRANSLATION_SYSTEM', label: '文字翻译系统' },
        { value: 'IMAGE_REVIEW_SYSTEM', label: '查稿系统' }
      ],
      languageList: [
        { value: 'SIMPLIFIED_CHINESE', label: '简体中文' },
        { value: 'TRADITIONAL_CHINESE', label: '繁體中文' },
        { value: 'KOREAN', label: '한국어' },
        { value: 'ENGLISH', label: 'ENGLISH' },
        { value: 'FRENCH', label: 'Français' },
        { value: 'GERMAN', label: 'Deutsch' },
        { value: 'JAPANESE', label: '日本語' },
        { value: 'ITALIAN', label: 'ITALIAN' }
      ],
      changeFlag: false,
      formData: {
        adminUser: {},
        sequenceNo: 1,
        hasChapterTitle: false,
        hasChapterCoverImage: false,
        departmentId: null,
        isMachineTranslation: true,
        platformId: null,
        adminUserId: null,
        isLocal: true,
        hasKpi: true,
        formType: 'COMIC',
        systemTypes: [],
        sourceLang: null,
        targetLang: null
      },
      oldData: {
        adminUser: {},
        sequenceNo: 1,
        hasChapterTitle: false,
        hasChapterCoverImage: false,
        departmentId: null,
        platformId: null,
        adminUserId: null,
        isMachineTranslation: true,
        isLocal: true,
        hasKpi: true,
        formType: 'COMIC',
        systemTypes: [],
        sourceLang: null,
        targetLang: null
      },
      isEditProduction: false,
      labelWidth: '80',
      layout: {
        labelCol: { style: 'width: 80px;', span: 4 },
        wrapperCol: { span: 20 }
      },
      rules: {
        /* productionName: [{ required: true, message: '请输入作品名称', trigger: 'blur' }], */
        adminUserId: [{ required: true, message: '请选择统筹人员', trigger: 'blur' }],
        platformId: [{ required: true, message: '请选择平台', trigger: 'blur' }],
        foreignName: [{ required: true, message: '请输入原文名', trigger: 'blur' }],
        productionName: [{ trigger: 'blur', validator: validateProductionName }]
      },
      departmentId: '',
      allUsers: []
    }
  },
  methods: {
    cancel() {
      this.isEditProduction = false
      this.$emit('loadData')
    },
    getCalcUnits(data) {
      let text = []
      data?.forEach(item => {
        let unit = ''
        switch (item.type) {
          case 'EACH':
            unit = '个'
            break
          case 'CHAPTER':
            unit = '话'
            break
          case 'VOLUME':
            unit = '部'
            break
          case 'PAGE':
            unit = '页'
            break
          case 'WORD':
            unit = '字'
            break
          case 'THOUSAND':
            unit = '千字'
            break
          case 'GRID':
            unit = '格'
            break
          case 'PERCENT':
            unit = '%'
            break
        }
        text.push(unit)
      })
      text = text.join('，')
      return text
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    userShow(user) {
      let userInfo = Vue.ls.get(USER_INFO)
      if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) {
        return true
      } else {
        if (user.userId == userInfo.id) {
          return true
        } else {
          return false
        }
      }
    },
    adminUserChange(value) {
      this.formData.adminUserId = value
      this.formData.adminUser.userId = value
    },
    getUser(user) {
      let id = user ? user.userId : null
      let text = ''
      if (id) {
        this.adminUsers.forEach(item => {
          if (id == item.userId) {
            text = item.userName
          }
        })
      }
      return text
    },
    getEditUser(id) {
      let text = ''
      if (id) {
        this.allUsers.forEach(item => {
          if (id == item.userId) {
            text = item.userName
          }
        })
      }
      return text
    },
    getIsedit() {
      this.$emit('getIsedit', this.isEditProduction)
    },
    editProduction() {
      if (this.isEditProduction) {
        let self = this
        if (this.platformList.length) {
          this.formData.adminUserId = this.platformList.filter(
            item => item.platformId == this.productionData.platformId
          )[0].adminUsers[0].userId
          this.formData.adminUser = this.platformList.filter(
            item => item.platformId == this.productionData.platformId
          )[0].adminUsers[0]
        }
        this.formData.hasChapterCoverImage = false
        this.formData.hasChapterTitle = false
        if (!this.formData.sequenceNo) {
          this.formData.sequenceNo = '0'
        }
        this.formData.calcUnits = this.calcUnits.map(item => {
          return { type: item, value: null }
        })
        this.$refs.form.validate(valid => {
          if (valid) {
            putAction('/production/edit', this.formData).then(res => {
              if (res.success) {
                self.$message.success({ content: '保存成功' })
                self.adminUsers.forEach(one => {
                  if (self.formData.adminUser.userId == one.id) {
                    self.formData.adminUser.userName = one.realname
                  }
                })
                self.platformList.forEach(one => {
                  if (self.formData.platformId == one.id) {
                    self.formData.platformName = one.platformName
                  }
                })
                self.$emit('setPagePattern', self.formData.calcUnits)
                self.isEditProduction = false
                self.$emit('getIsedit', false)
                self.$emit('changeProduction', self.formData)
              }
            })
          }
        })

        //保存
      } else {
        this.isEditProduction = true
        if (this.platformList.length) {
          this.formData.adminUserId = this.platformList.filter(
            item => item.platformId == this.productionData.platformId
          )[0].adminUsers[0].userId
          this.formData.adminUser = this.platformList.filter(
            item => item.platformId == this.productionData.platformId
          )[0].adminUsers[0]
        }

        if (this.formData.calcUnits.length) {
          this.calcUnits = this.formData.calcUnits.map(item => item.type)
        }
        /*         if (!this.formData.adminUser) {
          this.formData.adminUser = {}
        } */
      }
    },
    saveProduction(callback) {
      this.formData.adminUser.userId = this.formData.adminUserId
      this.formData.calcUnits = this.calcUnits.map(item => {
        return { type: item, value: null }
      })
      this.formData.hasChapterCoverImage = false
      this.formData.hasChapterTitle = false
      this.$refs.form.validate(valid => {
        if (valid) {
          postAction('/production/add', this.formData).then(res => {
            if (res.success) {
              this.$message.success({ content: '保存成功' })
              this.$emit('changeProduction', this.formData)
              callback(true, res.data.id)
            } else {
              this.$message.error({ content: res.message })
              callback(false)
            }
          })
        } else {
          callback(false)
        }
      })
    },
    handelPlatformChange() {
      if (!this.changeFlag) {
        this.changeFlag = true
        return
      }
      let platform = {}
      this.platformList.forEach(one => {
        if (one.platformId == this.formData.platformId) {
          this.formData.hasChapterTitle = one.hasChapterTitle
          this.formData.hasChapterCoverImage = one.hasChapterCoverImage
          this.formData.adminUserId = one.adminUsers[0].userId
          this.formData.adminUser = one.adminUsers[0]
          this.calcUnits = one.calcUnits?.map(item => item.type) || []
          this.departmentId = one.department.departId
          this.$set(this.formData, 'isLocal', one.isLocal)
          this.$set(this.formData, 'hasKpi', one.hasKpi)
          this.$set(this.formData, 'formType', one.formType)
          this.$set(this.formData, 'systemTypes', one.systemTypes)
          this.$set(this.formData, 'sourceLang', one.sourceLang)
          this.$set(this.formData, 'targetLang', one.targetLang)
          console.log(this.formData)
          /*           this.formData.isLocal = one.isLocal
          this.formData.hasKpi = one.hasKpi
          this.formData.formType = one.formType */
          platform = one
        }
      })
      this.formData.departName=this.platformList.find(item=>item.id==this.formData.platformId).department.departName
      this.$emit('changePlatForm', { id: this.formData.platformId, platform })
      this.getAllAdminUsers()
      this.$forceUpdate()
    },
    getAllAdminUsers() {
      return postAction('/sys/user/queryUser', {
        departmentIds: this.departmentId ? [this.departmentId] : null
        /* positionId: this.positionId, */
      }).then(({ data }) => {
        let arr = []
        data.forEach(item => {
          item.positions.forEach(i => {
            if (i.positionName == '统筹') {
              arr.push(item)
            }
          })
        })
        this.allUsers = data
        this.$emit('setAdmin', { arr, stageArr: data })
      })
    }
  },
  watch: {
    tab(n) {
      this.isEditProduction = false
    },
    formData: {
      handler: function(n, o) {
        let newData = JSON.stringify(this.formData)
        let oldData = JSON.stringify(this.oldData)
        if (newData != oldData) {
          this.$emit('getIsedit', this.isEditProduction)
        } else {
          this.$emit('getIsedit', false)
        }
      },
      deep: true
    },
    productionData: {
      immediate: true,
      handler() {
        console.log({ 'this.productionData': this.productionData })
        this.formData = { ...this.productionData }
        this.formData.adminUserId = this.formData.adminUser?.userId
        this.oldData = JSON.parse(JSON.stringify(this.formData))
        if (this.platformList.length) {
          this.formData.adminUserId = this.platformList.filter(
            item => item.platformId == this.productionData.platformId
          )[0].adminUsers[0].userId
          this.formData.adminUser = this.platformList.filter(
            item => item.platformId == this.productionData.platformId
          )[0].adminUsers[0]
        }
      }
    },
    platformList: {
      handler() {
        if (this.platformList.length) {
          let data = this.platformList.filter(item => item.platformId == this.productionData.platformId)[0]
          if (data) {
            this.formData.adminUserId = data.adminUsers[0].userId
            this.formData.adminUser = data.adminUsers[0]
          }
        }
        /*         this.formData.isLocal=data.isLocal
        this.formData.formType=data.formType
        this.formData.hasKpi=data.hasKpi */
      }
    }
    /* formData: {
      deep: true,
      handler() {
        console.log('watch formData deep', { 'this.formData': this.formData })
        this.$emit('input', this.formData)
      },
    }, */
  },
  created() {
    console.log({ 'tab-production created this.formData': this.formData })
  }
}
</script>

<style lang="less" scoped>
.ant-select-selection__rendered {
  img {
    display: none;
  }
}
</style>
