var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _vm.isEditProduction
            ? _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
          !_vm.isAddProduction && _vm.formData.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:info:button:edit",
                      expression: "'production:info:button:edit'",
                    },
                  ],
                  attrs: { type: "primary", icon: "edit" },
                  on: { click: _vm.editProduction },
                },
                [_vm._v(_vm._s(!_vm.isEditProduction ? "编辑" : "保存"))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isEditProduction || _vm.isAddProduction
        ? _c(
            "div",
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "form",
                    attrs: { model: _vm.formData, rules: _vm.rules },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "作品名称", prop: "productionName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入作品名称" },
                        model: {
                          value: _vm.formData.productionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productionName", $$v)
                          },
                          expression: "formData.productionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "原文名", prop: "foreignName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入原文名" },
                        model: {
                          value: _vm.formData.foreignName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "foreignName", $$v)
                          },
                          expression: "formData.foreignName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "暂用名", prop: "temporaryName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入暂用名" },
                        model: {
                          value: _vm.formData.temporaryName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "temporaryName", $$v)
                          },
                          expression: "formData.temporaryName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "序号" } },
                    [
                      _c("a-input-number", {
                        attrs: { "default-value": 1, placeholder: "序号" },
                        model: {
                          value: _vm.formData.sequenceNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sequenceNo", $$v)
                          },
                          expression: "formData.sequenceNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "平台", prop: "platformId" } },
                    [
                      _c(
                        "a-spin",
                        { attrs: { spinning: _vm.isLoadingPlatformList } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择平台" },
                              on: { change: _vm.handelPlatformChange },
                              model: {
                                value: _vm.formData.platformId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "platformId", $$v)
                                },
                                expression: "formData.platformId",
                              },
                            },
                            _vm._l(_vm.platformList, function (i) {
                              return _c(
                                "a-select-option",
                                { key: i.id, attrs: { disabled: !i.active } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        i.department.departName +
                                          "-" +
                                          i.platformName
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.departName &&
                  _vm.formData.departName.indexOf("原创") > -1
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "编辑" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                mode: "multiple",
                                placeholder: "请选择编辑人员",
                                "option-filter-prop": "label",
                                "show-search": "",
                              },
                              model: {
                                value: _vm.formData.allTaskAccessUserIds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "allTaskAccessUserIds",
                                    $$v
                                  )
                                },
                                expression: "formData.allTaskAccessUserIds",
                              },
                            },
                            [
                              _vm._l(
                                _vm.allUsers.filter((i) =>
                                  i.positions.every(
                                    (p) =>
                                      p.positionName != "BOSS" &&
                                      p.positionName != "HR"
                                  )
                                ),
                                function (i) {
                                  return [
                                    _c(
                                      "a-select-option",
                                      {
                                        key: i.userId,
                                        attrs: {
                                          label: i.userName,
                                          value: i.userId,
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "30px",
                                            height: "30px",
                                            "border-radius": "50%",
                                            "margin-right": "10px",
                                          },
                                          attrs: { src: i.avatar, alt: "" },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(i.userName)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "本地化", prop: "canTra" } },
                    [
                      _c("a-radio-group", {
                        attrs: {
                          options: [
                            { label: "是", value: true },
                            { label: "否", value: false },
                          ],
                        },
                        model: {
                          value: _vm.formData.isLocal,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isLocal", $$v)
                          },
                          expression: "formData.isLocal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "使用系统", prop: "formType" } },
                    [
                      _c("a-checkbox-group", {
                        staticStyle: { display: "flex", "line-height": "40px" },
                        attrs: { options: _vm.systemList },
                        model: {
                          value: _vm.formData.systemTypes,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "systemTypes", $$v)
                          },
                          expression: "formData.systemTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formData.systemTypes
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "是否机翻",
                            prop: "isMachineTranslation",
                          },
                        },
                        [
                          _c("a-radio-group", {
                            attrs: {
                              options: [
                                { label: "是", value: true },
                                { label: "否", value: false },
                              ],
                            },
                            model: {
                              value: _vm.formData.isMachineTranslation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "isMachineTranslation",
                                  $$v
                                )
                              },
                              expression: "formData.isMachineTranslation",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formData.systemTypes &&
                  _vm.formData.systemTypes.some(
                    (item) =>
                      item == "IMAGE_TRANSLATION_SYSTEM" ||
                      item == "TEXT_TRANSLATION_SYSTEM"
                  )
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "翻译语种" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: {
                                width: "150px",
                                "margin-right": "5px",
                              },
                              attrs: {
                                showSearch: "",
                                allowClear: "",
                                showArrow: "",
                                placeholder: "请选择源语种",
                                "option-filter-prop": "label",
                              },
                              model: {
                                value: _vm.formData.sourceLang,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "sourceLang", $$v)
                                },
                                expression: "formData.sourceLang",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "SIMPLIFIED_CHINESE" } },
                                [
                                  _vm._v(
                                    "\n              简体中文\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "TRADITIONAL_CHINESE" } },
                                [
                                  _vm._v(
                                    "\n              繁體中文\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "KOREAN" } },
                                [_vm._v("\n              한국어\n            ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "JAPANESE" } },
                                [_vm._v("\n              日本語\n            ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "ENGLISH" } },
                                [
                                  _vm._v(
                                    "\n              ENGLISH\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "FRENCH" } },
                                [
                                  _vm._v(
                                    "\n              Français\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "GERMAN" } },
                                [
                                  _vm._v(
                                    "\n              Deutsch\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "ITALIAN" } },
                                [
                                  _vm._v(
                                    "\n              ITALIAN\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("a-icon", { attrs: { type: "arrow-right" } }),
                          _c(
                            "a-select",
                            {
                              staticStyle: {
                                width: "150px",
                                "margin-left": "5px",
                              },
                              attrs: {
                                showSearch: "",
                                allowClear: "",
                                showArrow: "",
                                placeholder: "请选择目标语种",
                                "option-filter-prop": "label",
                              },
                              model: {
                                value: _vm.formData.targetLang,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "targetLang", $$v)
                                },
                                expression: "formData.targetLang",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "SIMPLIFIED_CHINESE" } },
                                [
                                  _vm._v(
                                    "\n              简体中文\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "TRADITIONAL_CHINESE" } },
                                [
                                  _vm._v(
                                    "\n              繁體中文\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "KOREAN" } },
                                [_vm._v("\n              한국어\n            ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "JAPANESE" } },
                                [_vm._v("\n              日本語\n            ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "ENGLISH" } },
                                [
                                  _vm._v(
                                    "\n              ENGLISH\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "FRENCH" } },
                                [
                                  _vm._v(
                                    "\n              Français\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "GERMAN" } },
                                [
                                  _vm._v(
                                    "\n              Deutsch\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "ITALIAN" } },
                                [
                                  _vm._v(
                                    "\n              ITALIAN\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "是否考核", prop: "hasKpi" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.formData.hasKpi,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "hasKpi", $$v)
                            },
                            expression: "formData.hasKpi",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: true } }, [
                            _vm._v("\n              是\n            "),
                          ]),
                          _c("a-radio", { attrs: { value: false } }, [
                            _vm._v("\n              否\n            "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "结算单位", prop: "calcUnits" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            mode: "multiple",
                            defaultValue: _vm.calcUnits,
                            "option-filter-prop": "label",
                            placeholder: "请选择",
                          },
                          on: { filterOption: _vm.filterOption },
                          model: {
                            value: _vm.calcUnits,
                            callback: function ($$v) {
                              _vm.calcUnits = $$v
                            },
                            expression: "calcUnits",
                          },
                        },
                        [
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "个",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every((item) => item != "EACH"),
                                value: "EACH",
                              },
                            },
                            [_vm._v("个")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "话",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every(
                                    (item) => item != "CHAPTER"
                                  ),
                                value: "CHAPTER",
                              },
                            },
                            [_vm._v("话")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "部",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every(
                                    (item) => item != "VOLUME"
                                  ),
                                value: "VOLUME",
                              },
                            },
                            [_vm._v("部")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "页",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every((item) => item != "PAGE"),
                                value: "PAGE",
                              },
                            },
                            [_vm._v("页")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "千字",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every(
                                    (item) => item != "THOUSAND"
                                  ),
                                value: "THOUSAND",
                              },
                            },
                            [_vm._v("千字")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "字",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every((item) => item != "WORD"),
                                value: "WORD",
                              },
                            },
                            [_vm._v("字")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "格",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every((item) => item != "GRID"),
                                value: "GRID",
                              },
                            },
                            [_vm._v("格")]
                          ),
                          _c(
                            "a-select-option",
                            {
                              attrs: {
                                label: "%",
                                disabled:
                                  _vm.calcUnits.length > 1 &&
                                  _vm.calcUnits.every(
                                    (item) => item != "PERCENT"
                                  ),
                                value: "PERCENT",
                              },
                            },
                            [_vm._v("%")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:info:page:view",
                  expression: "'production:info:page:view'",
                },
              ],
            },
            [
              _c(
                "info-item",
                { attrs: { labelWidth: _vm.labelWidth, label: "作品名称" } },
                [_vm._v(_vm._s(_vm.formData.productionName))]
              ),
              _c(
                "info-item",
                { attrs: { labelWidth: _vm.labelWidth, label: "原文名" } },
                [_vm._v(_vm._s(_vm.formData.foreignName))]
              ),
              _c(
                "info-item",
                { attrs: { labelWidth: _vm.labelWidth, label: "暂用名" } },
                [_vm._v(_vm._s(_vm.formData.temporaryName))]
              ),
              _c(
                "info-item",
                { attrs: { labelWidth: _vm.labelWidth, label: "序号" } },
                [_vm._v(_vm._s(_vm.formData.sequenceNo))]
              ),
              !_vm.isJf
                ? _c(
                    "info-item",
                    { attrs: { labelWidth: _vm.labelWidth, label: "平台" } },
                    [_vm._v(_vm._s(_vm.formData.platformName))]
                  )
                : _vm._e(),
              _vm.formData.departName &&
              _vm.formData.departName.indexOf("原创") > -1
                ? _c(
                    "info-item",
                    { attrs: { labelWidth: _vm.labelWidth, label: "编辑" } },
                    _vm._l(
                      _vm.formData.allTaskAccessUserIds,
                      function (userId, index) {
                        return _c("span", { key: index }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getEditUser(userId)) +
                              _vm._s(
                                index ==
                                  _vm.formData.allTaskAccessUserIds.length - 1
                                  ? ""
                                  : "，"
                              ) +
                              "\n        "
                          ),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
              !_vm.isJf
                ? _c(
                    "info-item",
                    {
                      attrs: { "label-width": _vm.labelWidth, label: "本地化" },
                    },
                    [_vm._v(_vm._s(_vm.formData.isLocal ? "是" : "否"))]
                  )
                : _vm._e(),
              _vm.formData.systemTypes &&
              _vm.formData.systemTypes.length &&
              !_vm.isJf
                ? _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "使用系统",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.formData.systemTypes
                            .map((item) => {
                              return _vm.systemList.find((i) => i.value == item)
                                ? _vm.systemList.find((i) => i.value == item)
                                    .label
                                : ""
                            })
                            .join(",")
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.formData.systemTypes && !_vm.isJf
                ? _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "是否机翻",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.formData.isMachineTranslation ? "是" : "否")
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.formData.systemTypes &&
              !_vm.isJf &&
              _vm.formData.systemTypes.some(
                (item) =>
                  item == "IMAGE_TRANSLATION_SYSTEM" ||
                  item == "TEXT_TRANSLATION_SYSTEM"
              )
                ? _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "翻译语种",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.languageList.find(
                              (item) => item.value == _vm.formData.sourceLang
                            )
                              ? _vm.languageList.find(
                                  (item) =>
                                    item.value == _vm.formData.sourceLang
                                ).label
                              : ""
                          )
                        ),
                      ]),
                      _c("a-icon", { attrs: { type: "arrow-right" } }),
                      _c("span", { staticStyle: { "margin-left": "5px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.languageList.find(
                              (item) => item.value == _vm.formData.targetLang
                            )
                              ? _vm.languageList.find(
                                  (item) =>
                                    item.value == _vm.formData.targetLang
                                ).label
                              : ""
                          )
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isJf
                ? _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "是否考核",
                      },
                    },
                    [_vm._v(_vm._s(_vm.formData.hasKpi ? "是" : "否"))]
                  )
                : _vm._e(),
              !_vm.isJf
                ? _c(
                    "info-item",
                    {
                      attrs: { labelWidth: _vm.labelWidth, label: "结算单位" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getCalcUnits(_vm.formData.calcUnits))
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }